import actions from './actions'
import { Action } from '../../shared/models/ReduxModel'

const initialState = {
  id: '',
  email: '',
  maazaName: '',
  commercialTitle: '',
  slug: '',
  token: '',
  logo: '',
  introImage: '',
  point: 0,
  about: '',
  confirmCode: '',
  confirm: false,
  status: '',
  roles: [],
  avatar: '',
  authorized: false, // false is default value
  loading: false,
  role: '',
}

export default function userReducer(state = initialState, action: Action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
