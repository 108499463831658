import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as jwt from 'shared/services/jwt'
import actions from './actions'
import {approvedMerchantMenu, appealMerchantMenu} from '../../constants/menu'
import { Action } from '../../shared/models/ReduxModel'

const mapAuthProviders = {
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

export function* LOGIN({ payload }: Action): any {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const result = yield call(mapAuthProviders[authProvider].login, email, password)
  if (result.id && result.token) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Giriş Yapıldı',
      description: 'Başarıyla giriş yaptınız!',
    })
  }
  else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
    if (result.data?.internalMessage === 'EmailNotConfirm') {
      // yield history.push({ pathname: `/auth/email-not-confirmed/${result.data.model.email}`, state: 'test'})
      yield put({
        type: 'user/SET_STATE',
        payload: {
          email: result.data.model.email,
        },
      })
    }
  }
}

export function* REGISTER({ payload }: Action): any {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Successful Register',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT(): any {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        ...response,
        authorized: true,
      },
    })
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuData: response.status === 1 ? [...approvedMerchantMenu, ...appealMerchantMenu] : appealMerchantMenu,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      email: '',
      maazaName: '',
      commercialTitle: '',
      slug: '',
      token: '',
      logo: '',
      introImage: '',
      point: 0,
      about: '',
      confirmCode: '',
      confirm: false,
      status: '',
      roles: [],
      avatar: '',
      authorized: false, // false is default value
      loading: false,
      role: '',
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
