export const approvedMerchantMenu = [
  /* {
    category: true,
    title: 'Anasayfa',
  },
  {
    title: 'Anasayfa',
    key: 'dashboards',
    icon: 'fe fe-home',
    url: '/',
  }, */

  {
    category: true,
    title: 'İçerik Yönetimi',
  },
  {
    title: 'Ürünler',
    key: 'productsListArea',
    icon: 'fe fe-inbox',
    children: [
      {
        title: 'Ürün Kataloğu',
        key: 'productCatalog ',
        url: '/products/add',
      },
      {
        title: 'Ürünlerim',
        key: 'myProductList',
        url: '/products',
      },
      {
        title: 'Excel ile Toplu Ürün Ekle',
        key: 'createWithExcel',
        url: '/products/create-with-excel',
      },
      {
        title: 'Yeni Ürün Ekle',
        key: 'addNewProduct',
        url: '/products/create-new-product',
      },
    ]
  },
  {
    title: 'Popüler Kategoriler',
    icon: 'fe fe-star',
    key: 'popularCategories',
    url: '/popular-categories',
  },
  {
    title: 'Onay Bekleyenler',
    icon: 'fe fe-check-square',
    key: 'commitProducts',
    url: '/products/commit-products',
  },
  {
    category: true,
    title: 'Ortam Kütüphanesi',
  },
  {
    title: 'Görsel Galerisi',
    icon: 'fe fe-image',
    key: 'gallery',
    url: '/image-gallery',
  },
  {
    category: true,
    title: 'Sipariş Yönetimi',
  },
  {
    title: 'Sipariş Yönetimi',
    key: 'myManagment',
    icon: 'fe fe-inbox',
    children: [
      {
        title: 'Onay Bekleyenler',
        key: 'pendingApprove',
        url: '/managment/pending-approve',
      },
      {
        title: 'Onaylananlar',
        key: 'approved',
        url: '/managment/approved',
      },
      {
        title: 'Kargolananlar',
        key: 'shipping',
        url: '/managment/shipping',
      },
      {
        title: 'Teslim Edilenler',
        key: 'deliverable',
        url: '/managment/deliverable',
      },
      {
        title: 'İptal Edilenler',
        key: 'canceled',
        url: '/managment/canceled',
      },
      {
        title: 'İade Edilenler',
        key: 'returns',
        url: '/managment/returns',
      }
    ]
  },
  {
    title: 'Komisyon Oranları',
    key: 'commissionRates',
    icon: 'fe fe-percent',
    url: '/commission-rates',
  },
  {
    title: 'Aynı Gün Teslimat Bölgeleri',
    key: 'shippingAdresses',
    icon: 'fe fe-truck',
    url: '/delivery/same-day-delivery-areas'
  },
  {
    category: true,
    title: 'İletişim',
  },
  {
    title: 'Destek Taleplerim',
    key: 'myTickets',
    icon: 'fe fe-edit-3',
    url: '/my-tickets',
  },
  // {
  //   category: true,
  //   title: 'Entegrasyon',
  // },
  // {
  //   title: 'API Anahtarı',
  //   key: 'apiTokens',
  //   icon: 'fe fe-command',
  //   url: '/integration/api-tokens',
  // },
  // {
  //   title: 'Web Kancası',
  //   key: 'webHooks',
  //   icon: 'fe fe-anchor',
  //   url: '/integration/webhooks',
  // },
  // {
  //   title: 'Entegrasyon Dökümanları',
  //   key: 'integrationDocuments',
  //   icon: 'fe fe-file',
  //   url: '/integration/docs',
  //   target: '_blank',
  // },
];

export const appealMerchantMenu = [
  {
    category: true,
    title: 'Başvuru Süreciniz',
  },
  {
    title: 'Gerekli Evraklar',
    key: 'appeal-documents',
    icon: 'fe fe-file',
    url: '/appeal-documents',
  },
  {
    title: 'Başvuru Bilgileriniz',
    key: 'appeal-details',
    icon: 'fe fe-eye',
    url: '/appeal-details',
  },
  {
    title: 'Mesajlar',
    key: 'appeal-messages',
    icon: 'fe fe-message-circle',
    url: '/appeal-messages',
    badgeType: 'danger'
  },
  {
    title: 'Başvuru İptali',
    key: 'appeal-cancellation',
    icon: 'fe fe-x-circle',
    url: '/appeal-cancellation',
  },
];
