import axios from 'axios'
import store from 'store'
import config from '../config'

const apiClient = axios.create({
  baseURL: config.apiUrl
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

export default apiClient
