import React, { useState } from 'react'
import styles from './style.module.scss'
import { Button, Image, Input, notification, Popover, Spin } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Label } from 'reactstrap'
import html2canvas from 'html2canvas'
import apiClient from '../../../services/axios'
import UserAgent from 'user-agents';

export interface FeedbackProps {

}

const Feedback: React.FC<FeedbackProps> = (props) => {
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [popoverIsVisible, setPopoverIsVisible] = useState(false);
  const [description, setDescription] = useState("");

  function submit() {

    const userAgent = new UserAgent();

    setLoading(true);
    apiClient.post('feedbacks', {url: window.location.href, image: imageSrc, description, userAgent: JSON.stringify(userAgent.data)}).then(resp => {
      notification.success({message: "Geri bildiriminiz alındı. Teşekkürler"});
      setPopoverIsVisible(false);
    }).finally(() => setLoading(false))
  }

  const Content = () => {
    return (
      <Spin spinning={loading}>

        <Label>Adres: </Label>
        <p>{window.location.href}</p>

        {
          imageSrc && (
            <>
              <Label>Görsel: </Label>
              <div>
                <Image width={100} height={100} src={imageSrc} />
              </div>
            </>
          )
        }

        <Label>Açıklama (Opsiyonel): </Label>
        <Input.TextArea rows={4} onChange={e => setDescription(e.target.value)} value={description} />

        <Button onClick={submit} type="primary" className="mt-3">Gönder</Button>
      </Spin>
    )
  }

  function screenShot(vis: any) {
    setPopoverIsVisible(vis);
    setImageSrc("");

    if (vis) {
      setLoading(true);
      html2canvas(document.body as HTMLElement).then((canvas) => {
        const data = canvas.toDataURL();
        setImageSrc(data);
      }).finally(() => setLoading(false));
    }

  }

  return (
    <div className={styles.wrapper}>
      {/* <Spin spinning={loading} className={styles.wrapper}>
        <Popover visible={popoverIsVisible} onVisibleChange={screenShot} placement="leftBottom" title="Geri Bildirim" content={Content} trigger="click">
          <Button icon={<QuestionCircleOutlined />}>Geri Bildirim</Button>
        </Popover>
      </Spin> */}
    </div>
  )
}

export default Feedback
