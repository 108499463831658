import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment/locale/tr'

import english from './locales/en-US'
import french from './locales/fr-FR'
import russian from './locales/ru-RU'
import chinese from './locales/zh-CN'
import turkish from './locales/tr-TR'

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json'
import currencyData from 'cldr-core/supplemental/currencyData.json'
import weekData from 'cldr-core/supplemental/weekData.json'
import numbers from 'cldr-numbers-full/main/tr/numbers.json'
import caGregorian from 'cldr-dates-full/main/tr/ca-gregorian.json'
import dateFields from 'cldr-dates-full/main/tr/dateFields.json'
import timeZoneNames from 'cldr-dates-full/main/tr/timeZoneNames.json'
import { load } from '@progress/kendo-react-intl'

load(likelySubtags, currencyData, weekData, numbers, caGregorian, dateFields, timeZoneNames)

moment.locale('tr')

const locales = {
  'en-US': english,
  'fr-FR': french,
  'ru-RU': russian,
  'zh-CN': chinese,
  'tr-TR': turkish
}

const mapStateToProps = ({ settings }) => ({ settings })

const Localization = ({ children, settings: { locale } }) => {
  const currentLocale = locales[locale]
  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
