import apiClient from 'shared/services/axios'
import store from 'store'

export async function login(email: string, password: string) {
  email = email.toLowerCase()
  return apiClient
    .post('Merchants/Login', { email, password })
    .then(response => {
      if (response) {
        const { token, id } = response.data.result
        if (token && id) {
          store.set('accessToken', token)
          store.set('userId', id)
          return response.data.result
        }
        return false
      }
      return false
    })
    .catch(err => err)
}

export async function register(email: string, password: string, name: string) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { token, id } = response.data.result
        if (token && id) {
          store.set('accessToken', token)
          store.set('userId', id)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  const userId = store.get('userId')
  return userId ? apiClient
    .get(`Merchants/${userId}`)
    .then(response => {
      if (response) {
        const { token, id } = response.data.result
        if (token && id) {
          store.set('accessToken', token)
          store.set('userId', id)
        }
        return response.data.result
      }
      return false
    })
    .catch(err => console.log(err)) : false
}

export async function logout() {
  store.remove('accessToken')
  store.remove('userId')
  return true
}
