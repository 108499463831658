import React, { Suspense, useEffect } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import Layout from 'layouts'
import { commonRoutes, approvedMerchantRoutes, appealMerchantRoutes } from './constants/routes'
import apiClient from './shared/services/axios'
import { notification } from 'antd'
import useInterval from './shared/hooks/useInterval'
import store from 'store'

const mapStateToProps = ({ settings, user, menu }) => ({
  routerAnimation: settings.routerAnimation,
  user,
  menu,
})




const Router = ({ history, routerAnimation, dispatch, user, menu }) => {
  const routes = commonRoutes.concat(
    user.status === 1 ? [...approvedMerchantRoutes, ...appealMerchantRoutes] : appealMerchantRoutes,
  )
  const id = store.get('userId')


  console.log('user', user);

  useEffect(() => {
    apiClient.interceptors.response.use(undefined, error => {
      // Errors handling
      const { response } = error
      const { data } = response

      if (response) {
        const { noToastr } = error.config.headers

        if (error.status === 401 || error.status === 403) {
          dispatch({
            type: 'user/LOGOUT',
          })
        }

        if (noToastr) {
          console.log()
        } else if (typeof data.errors === 'object' && Object.keys(data.errors).length) {
          const validateErrors = []

          Object.keys(data.errors).forEach(key => {
            if (data.errors[key].length > 0) {
              validateErrors.push(data.errors[key].join(','))
            }
          })

          notification.warning({
            message: 'Formda Bazı Hatalar Mevcut',
            description: validateErrors.join(', '),
          })
        } else {
          notification.error({
            message: data?.message || response?.statusText || data,
          })
        }
      }

      return Promise.reject(error.response)
    })
  }, [])

  useInterval(() => {
    if (id) {
      apiClient.get('MerchantAppeals/GetUnReadMessagesCount').then(response => {
        const unreadCount = response.data.result
        const menuItem = menu.menuData.find(i => i.key === 'appeal-messages')
        if (menuItem) {
          menuItem.count = unreadCount > 0 ? unreadCount : undefined
        }
        dispatch({
          type: 'menu/SET_STATE',
        })
      })
    }
  }, 30000)

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <Redirect to={user.status === 1 ? '/products' : '/appeal-documents'} />
                      )}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div
                              className={routerAnimation}
                              style={{ flex: 1, flexDirection: 'column', display: 'flex' }}
                            >
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
