import localeAntd from 'antd/es/locale/tr_TR'

localeAntd.Image = { preview: 'Önizleme' }

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Arama...',
  'topBar.findPages': 'Sayfaları bul...',
  'topBar.actions': 'İşlemler',
  'topBar.status': 'Durum',
  'topBar.profileMenu.hello': 'Merhaba',
  'topBar.profileMenu.billingPlan': 'Ödeme Planı',
  'topBar.profileMenu.role': 'Rol',
  'topBar.profileMenu.email': 'E-posta',
  'topBar.profileMenu.phone': 'Telefon',
  'topBar.profileMenu.editProfile': 'Mağaza Profili',
  'topBar.profileMenu.updatePassword': 'Şifre Değiştir',
  'topBar.profileMenu.logout': 'Çıkış',
}

export default {
  locale: 'tr-TR',
  localeAntd,
  messages,
}
