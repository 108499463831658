import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import TopBar from 'components/cleanui/layout/TopBar'
import Menu from 'components/cleanui/layout/Menu'
import Feedback from '../../shared/components/codeove/Feedback'

const mapStateToProps = ({ settings }) => ({
  isContentMaxWidth: settings.isContentMaxWidth,
  isAppMaxWidth: settings.isAppMaxWidth,
  isGrayBackground: settings.isGrayBackground,
  isSquaredBorders: settings.isSquaredBorders,
  isCardShadow: settings.isCardShadow,
  isBorderless: settings.isBorderless,
  isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  leftMenuWidth: settings.leftMenuWidth,
  isMobileMenuOpen: settings.isMobileMenuOpen,
  isMenuCollapsed: settings.isMenuCollapsed,
  isMobileView: settings.isMobileView,
})

const MainLayout = ({
                      children,
                      leftMenuWidth : defaultLeftMenuWidth,
                      isMobileView,
                      isMenuCollapsed,
                    }) => {
  let leftMenuWidth = defaultLeftMenuWidth;

  if (isMobileView) {
    leftMenuWidth = 0;
  } else if (isMenuCollapsed) {
    leftMenuWidth = 80;
  }

  return (
    <Layout>

      <Menu />

      <Layout>
        <Layout.Header className="cui__layout__header" style={{ position: 'fixed', zIndex: 1, right: 0, left: leftMenuWidth,  backgroundColor: 'white' }}>
          <TopBar />
        </Layout.Header>

        <div style={{height : 64, width: '100%'}} />

        {/* <Breadcrumbs /> */}

        <Layout.Content style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column'}}>
          <div className="cui__utils__content pt-3" style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column'}}>
            {children}
          </div>
        </Layout.Content>
      </Layout>

    </Layout>
  )

  // return (
  //   <div className={classNames({ cui__layout__grayBackground: isGrayBackground })}>
  //     <Layout
  //       className={classNames({
  //         cui__layout__contentMaxWidth: isContentMaxWidth,
  //         cui__layout__appMaxWidth: isAppMaxWidth,
  //         cui__layout__grayBackground: isGrayBackground,
  //         cui__layout__squaredBorders: isSquaredBorders,
  //         cui__layout__cardsShadow: isCardShadow,
  //         cui__layout__borderless: isBorderless,
  //       })}
  //     >
  //       <Menu />
  //       <Layout>
  //         <Layout.Header style={{ position: 'fixed', zIndex: 1, right: 0, left: leftMenuWidth,  backgroundColor: 'white' }}>
  //           <TopBar />
  //         </Layout.Header>
  //         <Layout.Content style={{ display: 'flex', flexDirection: 'column', paddingTop: 64}}>
  //           {children}
  //         </Layout.Content>
  //       </Layout>
  //     </Layout>
  //   </div>
  // )
}

export default withRouter(connect(mapStateToProps)(MainLayout))
