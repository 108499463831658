import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Menu, Dropdown, Avatar } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(7)

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    setCount(count + 1)
  }

  const menu = (
    <Menu selectable={false}>
      {
        user.status === 1 && (
          <>
            <Menu.Item>
              <a href="/store-profile">
                <i className="fe fe-user mr-2" />
                <FormattedMessage id="topBar.profileMenu.editProfile" />
              </a>
            </Menu.Item>
            <Menu.Divider />
          </>
        )
      }
      <Menu.Item>
        <a href="/update-password">
          <i className="fe fe-lock mr-2" />
          <FormattedMessage id="topBar.profileMenu.updatePassword" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        <Avatar
          style={{ backgroundColor: 'transparent', border: '1px solid #e4e9f0'}}
          shape="square"
          size="large"
          icon={<img src={user.logo || 'resources/images/avatars/avatar-2.png'} alt="Logo" />}
        />
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
