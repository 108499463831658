import { lazy } from 'react'

export const commonRoutes = [
  {
    path: '/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/appeal-cancelled',
    Component: lazy(() => import('pages/auth/appeal-cancelled')),
    exact: true,
  },
  {
    path: '/auth/reset-password/:guid',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/confirm-registration/:guid',
    Component: lazy(() => import('pages/auth/confirm-registration')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  {
    path: '/integration/docs',
    Component: lazy(() => import('pages/integration/Docs')),
    exact: true,
  },
]

export const appealMerchantRoutes = [
  {
    path: '/appeal-documents',
    Component: lazy(() => import('pages/appeal-tracking/AppealDocuments')),
    exact: true,
  },
  {
    path: '/appeal-details',
    Component: lazy(() => import('pages/appeal-tracking/AppealDetails')),
    exact: true,
  },
  {
    path: '/appeal-messages',
    Component: lazy(() => import('pages/appeal-tracking/AppealMessages')),
    exact: true,
  },
  {
    path: '/appeal-cancellation',
    Component: lazy(() => import('pages/appeal-tracking/AppealCancellation')),
    exact: true,
  },
  {
    path: '/update-password',
    Component: lazy(() => import('pages/update-password')),
    exact: true,
  },
]

export const approvedMerchantRoutes = [
  {
    path: '/my-tickets',
    Component: lazy(() => import('pages/tickets/MyTickets')),
    exact: true,
  },
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/products/ProductList')),
    exact: true,
  },
  {
    path: '/examples/datatable',
    Component: lazy(() => import('pages/examples/datatable')),
    exact: true,
  },
  {
    path: '/image-gallery',
    Component: lazy(() => import('pages/image-gallery')),
    exact: true,
  },
  {
    path: '/products/add',
    Component: lazy(() => import('pages/products/ProductAdd')),
    exact: true,
  },
  {
    path: '/products',
    Component: lazy(() => import('pages/products/ProductList')),
    exact: true,
  },
  {
    path: '/products',
    Component: lazy(() => import('pages/products/ProductList')),
    exact: true,
  },
  {
    path: '/orders/:id',
    Component: lazy(() => import('pages/orders/OrdersList')),
    exact: true,
  },
  {
    path: '/managment/:id',
    Component: lazy(() => import('pages/orders/OrdersManagment')),
    exact: true,
  },
  {
    path: '/products/commit-products',
    Component: lazy(() => import('pages/products/ProductCommitList')),
    exact: true,
  },
  {
    path: '/products/create',
    Component: lazy(() => import('pages/products/ProductCreate')),
    exact: true,
  },
  {
    path: '/products/create-new-product',
    Component: lazy(() => import('pages/products/ProductCreatePage')),
    exact: true,
  },
  {
    path: '/products/create-with-excel',
    Component: lazy(() => import('pages/products/ProductImportModalPage')),
    exact: true,
  },

  {
    path: '/delivery/same-day-delivery-areas',
    Component: lazy(() => import('pages/delivery/SameDayDeliveryAreas')),
    exact: true,
  },
  {
    path: '/store-profile',
    Component: lazy(() => import('pages/store-profile')),
    exact: true,
  },
  {
    path: '/commission-rates',
    Component: lazy(() => import('pages/commission-rates')),
    exact: true,
  },
  {
    path: '/popular-categories',
    Component: lazy(() => import('pages/popular-categories/PopularCategoryList')),
    exact: true,
  },
  {
    path: '/integration/api-tokens',
    Component: lazy(() => import('pages/integration/ApiTokens')),
    exact: true,
  },
  {
    path: '/integration/webhooks',
    Component: lazy(() => import('pages/integration/WebHooks')),
    exact: true,
  },
]
